@import '../../input/style/mixin';

@select-prefix-cls: ~'@{ant-prefix}-select';

.select-status-color(
  @text-color;
  @border-color;
  @background-color;
  @hoverBorderColor;
  @outlineColor;
) {
  &.@{select-prefix-cls}:not(.@{select-prefix-cls}-disabled):not(.@{select-prefix-cls}-customize-input) {
    .@{select-prefix-cls}-selector {
      background-color: @background-color;
      border-color: @border-color !important;
    }
    &.@{select-prefix-cls}-open .@{select-prefix-cls}-selector,
    &.@{select-prefix-cls}-focused .@{select-prefix-cls}-selector {
      .active(@border-color, @hoverBorderColor, @outlineColor);
    }
  }
}

.@{select-prefix-cls} {
  &-status-error {
    .select-status-color(@error-color, @error-color, @select-background, @error-color-hover, @error-color-outline);
  }

  &-status-warning {
    .select-status-color(@warning-color, @warning-color, @input-bg, @warning-color-hover, @warning-color-outline);
  }

  &-status-error,
  &-status-warning,
  &-status-success,
  &-status-validating {
    &.@{select-prefix-cls}-has-feedback {
      //.@{prefix-cls}-arrow,
      .@{select-prefix-cls}-clear {
        right: 32px;
      }

      .@{select-prefix-cls}-selection-selected-value {
        padding-right: 42px;
      }
    }
  }
}
